import { createRouter, createWebHistory } from 'vue-router';
// import Home from '../views/Home.vue';

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/Login.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/member/Profile.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/login/Register.vue')
  },
  {
    path: '/mapexample',
    name: 'Mapexample',
    component: () => import('../views/Mapexample.vue')
  },
  {
    path: '/sendsamplesurvey/:id',
    name: 'SendsampleSurvey',
    props: true,
    component: () => import('../views/survey/cropdamageproblem/SendsampleSurvey.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/sampleno2survey/:id',
    name: 'Sampleno2Survey',
    props: true,
    component: () => import('../views/survey/sampleno2/Sampleno2Survey.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/sampleno2ans',
    name: 'Sampleno2Ans',
    component: () => import('../views/survey/sampleno2/Sampleno2Ans.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/sampleno2anslist/:memberId',
    name: 'Sampleno2Anslist',
    props: true,
    component: () => import('../views/survey/sampleno2/Sampleno2Anslist.vue'),
    meta: { authorize: ['admin'] }
  },
  {
    path: '/sampleno2map',
    name: 'Sampleno2Map',
    component: () => import('../views/survey/sampleno2/Sampleno2Map.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/samplerainsurvey/:id',
    name: 'SamplerainSurvey',
    props: true,
    component: () => import('../views/survey/samplerain/SamplerainSurvey.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/samplerainans',
    name: 'SamplerainAns',
    component: () => import('../views/survey/samplerain/SamplerainAns.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/samplerainanslist/:memberId',
    name: 'SamplerainAnslist',
    props: true,
    component: () => import('../views/survey/samplerain/SamplerainAnslist.vue'),
    meta: { authorize: ['admin'] }
  },
  {
    path: '/samplerainmap',
    name: 'SamplerainMap',
    component: () => import('../views/survey/samplerain/SamplerainMap.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/samplewatersurvey/:id',
    name: 'SamplewaterSurvey',
    props: true,
    component: () => import('../views/survey/samplewater/SamplewaterSurvey.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/samplewaterans',
    name: 'SamplewaterAns',
    component: () => import('../views/survey/samplewater/SamplewaterAns.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/samplewateranslist/:memberId',
    name: 'SamplewaterAnslist',
    props: true,
    component: () => import('../views/survey/samplewater/SamplewaterAnslist.vue'),
    meta: { authorize: ['admin'] }
  },
  {
    path: '/samplewatermap',
    name: 'SamplewaterMap',
    component: () => import('../views/survey/samplewater/SamplewaterMap.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/mysurvey',
    name: 'mysurvey',
    component: () => import('../views/survey/Mysurvey.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/cropdamageproblem/:id',
    name: 'CropdamageproblemSurvey',
    props: true,
    component: () => import('../views/survey/cropdamageproblem/CropdamageproblemSurvey.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/cropdamageproblemmap',
    name: 'CropdamageproblemMap',
    component: () => import('../views/survey/cropdamageproblem/CropdamageproblemMap.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/cropdamageans',
    name: 'Cropdamageans',
    component: () => import('../views/survey/cropdamageproblem/Cropdamageans.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/cropdamageanslist/:memberId',
    name: 'Cropdamageanslist',
    props: true,
    component: () => import('../views/survey/cropdamageproblem/Cropdamageanslist.vue'),
    meta: { authorize: ['admin'] }
  },
  {
    path: '/cropdamageview/:id',
    name: 'CropdamageView',
    props: true,
    component: () => import('../views/survey/cropdamageproblem/CropdamageView.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/researcher/cropdamageans',
    name: 'researcherCropdamageans',
    component: () => import('../views/survey/cropdamageproblem/researcher/Cropdamageans.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/sampleans',
    name: 'Sampleans',
    component: () => import('../views/survey/cropdamageproblem/Sampleans.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/sampleanslist/:memberId',
    name: 'Sampleanslist',
    props: true,
    component: () => import('../views/survey/cropdamageproblem/Sampleanslist.vue'),
    meta: { authorize: ['admin'] }
  },
  {
    path: '/presamplesurvey',
    name: 'PresampleSurvey',
    component: () => import('../views/survey/cropdamageproblem/PresampleSurvey.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/sendsampleview/:id',
    name: 'SendsampleView',
    props: true,
    component: () => import('../views/survey/cropdamageproblem/SendsampleView.vue'),
    meta: { authorize: ['member', 'admin'] }
  },

  // Admin Router
  {
    path: '/organizemanagement',
    name: 'organizemanagement',
    component: () => import('../views/admin/OrganizeManagement.vue'),
    meta: { authorize: ['admin'] }
  },
  {
    path: '/screenmanagement',
    name: 'screenmanagement',
    component: () => import('../views/admin/ScreenMangement.vue'),
    meta: { authorize: ['admin', 'csite'] }
  },
  {
    path: '/usermanagement',
    name: 'usermanagement',
    component: () => import('../views/admin/UserManagement.vue'),
    meta: { authorize: ['admin'] }
  },
  {
    path: '/useroforganize/:id',
    name: 'Useroforganize',
    props: true,
    component: () => import('../views/admin/Useroforganize.vue'),
    meta: { authorize: ['admin'] }
  },
  {
    path: '/adminsurvey',
    name: 'adminsurvey',
    component: () => import('../views/survey/Adminsurvey.vue'),
    meta: { authorize: ['admin'] }
  },
  // End Admin Router

  {
    path: '/orangeorchardsurvey/:id',
    name: 'OrangeorchardSurvey',
    props: true,
    component: () => import('../views/survey/orangeorchard/OrangeorchardSurvey.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/orangeorchardans',
    name: 'OrangeorchardAns',
    component: () => import('../views/survey/orangeorchard/OrangeorchardAns.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/orangeorchardanslist/:memberId',
    name: 'OrangeorchardAnslist',
    props: true,
    component: () => import('../views/survey/orangeorchard/OrangeorchardAnslist.vue'),
    meta: { authorize: ['admin'] }
  },
  {
    path: '/orangeorchardmap',
    name: 'OrangeorchardMap',
    component: () => import('../views/survey/orangeorchard/OrangeorchardMap.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/spst20survey/:id',
    name: 'SPST20Survey',
    props: true,
    component: () => import('../views/survey/SPST20/SPST20Survey.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/spst20ans',
    name: 'SPST20Ans',
    component: () => import('../views/survey/SPST20/SPST20Ans.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/spst20anslist/:memberId?',
    name: 'SPST20Anslist',
    props: true,
    component: () => import('../views/survey/SPST20/SPST20Anslist.vue'),
    meta: { authorize: ['admin'] }
  },
  {
    path: '/spst20map',
    name: 'SPST20Map',
    component: () => import('../views/survey/SPST20/SPST20Map.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/bioblitzsurvey/:id',
    name: 'BioblitzSurvey',
    props: true,
    component: () => import('../views/survey/bioblitz/BioblitzSurvey.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/bioblitzans',
    name: 'BioblitzAns',
    component: () => import('../views/survey/bioblitz/BioblitzAns.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/bioblitzanslist/:memberId',
    name: 'BioblitzAnslist',
    props: true,
    component: () => import('../views/survey/bioblitz/BioblitzAnslist.vue'),
    meta: { authorize: ['admin'] }
  },
  {
    path: '/bioblitzmap',
    name: 'BioblitzMap',
    component: () => import('../views/survey/bioblitz/BioblitzMap.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/agriculturalproductsurvey/:id',
    name: 'AgriculturalproductSurvey',
    props: true,
    component: () => import('../views/survey/agriculturalproduct/AgriculturalproductSurvey.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/agriculturalproductans',
    name: 'AgriculturalproductAns',
    component: () => import('../views/survey/agriculturalproduct/AgriculturalproductAns.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/agriculturalproductanslist/:memberId',
    name: 'AgriculturalproductAnslist',
    props: true,
    component: () => import('../views/survey/agriculturalproduct/AgriculturalproductAnslist.vue'),
    meta: { authorize: ['admin'] }
  },
  {
    path: '/agriculturalproductmap',
    name: 'AgriculturalproductMap',
    component: () => import('../views/survey/agriculturalproduct/AgriculturalproductMap.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/physicalhealthsurvey/:id',
    name: 'PhysicalhealthSurvey',
    props: true,
    component: () => import('../views/survey/physicalhealth/PhysicalhealthSurvey.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/Physicalhealthans',
    name: 'PhysicalhealthAns',
    component: () => import('../views/survey/physicalhealth/PhysicalhealthAns.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/Physicalhealthanslist/:memberId',
    name: 'PhysicalhealthAnslist',
    props: true,
    component: () => import('../views/survey/physicalhealth/PhysicalhealthAnslist.vue'),
    meta: { authorize: ['admin'] }
  },
  {
    path: '/Physicalhealthmap',
    name: 'PhysicalhealthMap',
    component: () => import('../views/survey/physicalhealth/PhysicalhealthMap.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/lichensurvey/:id',
    name: 'LichenSurvey',
    props: true,
    component: () => import('../views/survey/lichen/LichenSurvey.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/lichenmap',
    name: 'LichenMap',
    component: () => import('../views/survey/lichen/LichenMap.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/lichenans',
    name: 'Lichenans',
    component: () => import('../views/survey/lichen/Lichenans.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/lichenanslist/:memberId',
    name: 'Lichenanslist',
    props: true,
    component: () => import('../views/survey/lichen/Lichenanslist.vue'),
    meta: { authorize: ['admin'] }
  },

  /* mercury zone */
  {
    path: '/mercurymap',
    name: 'MercuryMap',
    component: () => import('../views/survey/mercury/MercuryMap.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/mercurysurvey/:id',
    name: 'MercurySurvey',
    props: true,
    component: () => import('../views/survey/mercury/MercurySurvey.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/mercuryans',
    name: 'MercuryAns',
    component: () => import('../views/survey/mercury/MercuryAns.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/mercuryanslist/:memberId',
    name: 'MercuryAnslist',
    props: true,
    component: () => import('../views/survey/mercury/MercuryAnslist.vue'),
    meta: { authorize: ['admin'] }
  },
  {
    path: '/mercuryview/:id',
    name: 'MercuryView',
    props: true,
    component: () => import('../views/survey/mercury/MercuryView.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/mercuryriskmap',
    name: 'MercuryriskMap',
    component: () => import('../views/survey/mercury/MercuryriskMap.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  /* end mercury zone */

  /* SmokeandsoundSurvey */
  {
    path: '/smokeandsoundsurvey/:id',
    name: 'SmokeandsoundSurvey',
    props: true,
    component: () => import('../views/survey/smokeandsound/SmokeandsoundSurvey.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/smokeandsoundans',
    name: 'SmokeandsoundAns',
    component: () => import('../views/survey/smokeandsound/SmokeandsoundAns.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/smokeandsoundanslist/:memberId',
    name: 'SmokeandsoundAnslist',
    props: true,
    component: () => import('../views/survey/smokeandsound/SmokeandsoundAnslist.vue'),
    meta: { authorize: ['admin'] }
  },
  {
    path: '/smokeandsoundmap',
    name: 'SmokeandsoundMap',
    component: () => import('../views/survey/smokeandsound/SmokeandsoundMap.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  /* end SmokeandsoundSurvey */
  {
    path: '/lichen2/:serveyid',
    name: 'lichen2',
    props: true,
    component: () => import('../views/survey/lichen2/Lichen2.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/lichenans2',
    name: 'lichenans2',
    component: () => import('../views/survey/lichen2/LichenAns2.vue'),
    meta: { authorize: ['member', 'admin'] }
  },
  {
    path: '/loginbycsite/:token',
    name: 'loginbycsite',
    props: true,
    component: () => import('../views/login/LoginByCsite.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  // const publicPages = ['/Login', '/register', '/loginbycsite'];
  // const authRequired = !publicPages.includes(to.path);

  const publicPages = ['Login', 'Register', 'loginbycsite'];
  const authRequired = !publicPages.includes(to.name);
  const loggedIn = localStorage.getItem('member');
  const memberData = JSON.parse(loggedIn);
  const token = localStorage.getItem('token');
  const { authorize } = to.meta;

  if (authRequired && !loggedIn && !token) {
    return next('/login');
  }

  if (authorize) {
    if (authorize.length && !authorize.includes(memberData.role)) {
      // role not authorised so redirect to home page
      return next({ path: '/' });
    }
  }

  if (to.path === '/') {
    if (memberData.role === 'admin') {
      // return next('/organizemanagement');
      return next('/mysurvey');
    } else {
      return next('/mysurvey');
    }
  }

  next();
});

export default router;
