import HttpRequest from './core/http_request';

class SurveyProvider extends HttpRequest {
  getStatus (data) {
    return this.request('get', '/uploadFile');
  }

  getSurvey (data) {
    return this.request('get', '/survey/surveyLichen', data);
  }

  getMySurveylichen (data) {
    return this.request('get', '/survey/mysurveylichen', data);
  }

  saveSurvey (data) {
    return this.request('post', '/survey/save');
  }

  saveSurveyLichen (data) {
    return this.request('post', '/survey/surveyLichen', data);
  }

  uploadFileLichen (data) {
    return this.request('upload', '/uploadFile/uploadLichenFile', data);
  }

  getSurveyLichen (data) {
    const id = data._id;
    return this.request('get', '/survey/surveyLichen/id/' + id, data);
  }

  updateSurveyLichen (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/survey/surveyLichen/id/' + id, data);
  }

  saveSurveyCropDamage (data) {
    return this.request('post', '/survey/surveycorpDamage', data);
  }

  uploadFileCrop (data) {
    return this.request('upload', '/uploadFile/uploadCorpDamageFile', data);
  }

  getSurveyCropDamage (data) {
    return this.request('get', '/survey/surveycorpDamage', data);
  }

  getMySurveyCorpDamage (data) {
    return this.request('get', '/survey/mySurveyCorpDamage', data);
  }

  updateSurveyCropDamage (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/survey/surveycorpDamage/id/' + id, data);
  }

  getSurveyCropDamageAnswer (data) {
    const id = data._id;
    delete data._id;
    return this.request('get', '/survey/surveycorpDamage/id/' + id, data);
  }

  getSurveyCropOne (data) {
    const id = data._id;
    return this.request('get', '/survey/surveycorpDamage/id/' + id, data);
  }

  uploadFileBioblitz (data) {
    return this.request('upload', '/uploadFile/uploadBioblitzFile', data);
  }

  saveSurveyBioblitz (data) {
    return this.request('post', '/survey/surveyBioBlitz', data);
  }

  getSurveyBioblitzAnsList (data) {
    return this.request('get', '/survey/surveyBioBlitz', data);
  }

  getMySurveyBioBlitz (data) {
    return this.request('get', '/survey/mySurveyBioBlitz', data);
  }

  getSurveyBioblitz (data) {
    const id = data._id;
    return this.request('get', '/survey/surveyBioBlitz/id/' + id, data);
  }

  updateSurveyBioblitz (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/survey/surveyBioBlitz/id/' + id, data);
  }

  uploadFileAgriculturalproduct (data) {
    return this.request('upload', '/uploadFile/uploadAgriculturalproductFile', data);
  }

  saveSurveyAgriculturalproduct (data) {
    return this.request('post', '/survey/surveyAgriculturalproduct', data);
  }

  getSurveyAgriculturalproductAnsList (data) {
    return this.request('get', '/survey/surveyAgriculturalproduct', data);
  }

  getMySurveyAgriculturalProduct (data) {
    return this.request('get', '/survey/mySurveyAgriculturalProduct', data);
  }

  getSurveyAgriculturalproduct (data) {
    const id = data._id;
    return this.request('get', '/survey/surveyAgriculturalproduct/id/' + id, data);
  }

  updateSurveyAgriculturalproduct (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/survey/surveyAgriculturalproduct/id/' + id, data);
  }

  updateSurveyPhysicalhealth (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/survey/surveyPhysicalhealth/id/' + id, data);
  }

  saveSurveyPhysicalhealth (data) {
    return this.request('post', '/survey/surveyPhysicalhealth', data);
  }

  getSurveyPhysicalhealthAnsList (data) {
    return this.request('get', '/survey/surveyPhysicalhealth', data);
  }

  getMySurveyPhysicalHealth (data) {
    return this.request('get', '/survey/mySurveyPhysicalHealth', data);
  }

  getSurveyPhysicalhealth (data) {
    const id = data._id;
    return this.request('get', '/survey/surveyPhysicalhealth/id/' + id, data);
  }

  /* sampleno2survey */
  saveSurveySampleno2 (data) {
    return this.request('post', '/survey/surveySampleNo2', data);
  }

  updateSurveySampleno2 (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/survey/surveySampleNo2/id/' + id, data);
  }

  getSurveySampleno2List (data) {
    return this.request('get', '/survey/surveySampleNo2', data);
  }

  getMySurveySampleNO2 (data) {
    return this.request('get', '/survey/mySurveySampleNO2', data);
  }

  getSurveySampleno2 (data) {
    const id = data._id;
    return this.request('get', '/survey/surveySampleNo2/id/' + id, data);
  }

  uploadSampleNO2File (data) {
    return this.request('upload', '/uploadFile/uploadSampleNO2File', data);
  }
  /* end sampleno2survey */

  /* samplerainwatersurvey */
  saveSurveySamplenrain (data) {
    return this.request('post', '/survey/surveySamplerain', data);
  }

  updateSurveySamplenrain (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/survey/surveySamplerain/id/' + id, data);
  }

  getSurveySamplenrainList (data) {
    return this.request('get', '/survey/surveySamplerain', data);
  }

  getMySurveySampleRain (data) {
    return this.request('get', '/survey/mySurveySampleRain', data);
  }

  getSurveySamplenrain (data) {
    const id = data._id;
    return this.request('get', '/survey/surveySamplerain/id/' + id, data);
  }

  uploadSampleRainFile (data) {
    return this.request('upload', '/uploadFile/uploadSampleRainFile', data);
  }
  /* end samplerainwatersurvey */

  /* samplewatersurvey */
  saveSurveySamplewater (data) {
    return this.request('post', '/survey/surveySamplewater', data);
  }

  updateSurveySamplewater (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/survey/surveySamplewater/id/' + id, data);
  }

  getSurveySamplewaterList (data) {
    return this.request('get', '/survey/surveySamplewater', data);
  }

  getMySurveySampleWater (data) {
    return this.request('get', '/survey/mySurveySampleWater', data);
  }

  getSurveySamplewater (data) {
    const id = data._id;
    return this.request('get', '/survey/surveySamplewater/id/' + id, data);
  }

  uploadSampleWaterFile (data) {
    return this.request('upload', '/uploadFile/uploadSampleWaterFile', data);
  }
  /* end samplewatersurvey */

  /* orangeorchardsurvey */
  saveSurveyOrangeorchard (data) {
    return this.request('post', '/survey/surveyOrangeorchard', data);
  }

  updateSurveyOrangeorchard (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/survey/surveyOrangeorchard/id/' + id, data);
  }

  getSurveyOrangeorchardList (data) {
    return this.request('get', '/survey/surveyOrangeorchard', data);
  }

  getMySurveyOrangeOrchard (data) {
    return this.request('get', '/survey/mySurveyOrangeOrchard', data);
  }

  getSurveyOrangeorchard (data) {
    const id = data._id;
    return this.request('get', '/survey/surveyOrangeorchard/id/' + id, data);
  }

  uploadFileOrangeorchard (data) {
    return this.request('upload', '/uploadFile/uploadOrangeOrchardFile', data);
  }
  /* end orangeorchardsurvey */

  /* SPST20Survey */
  saveSurveySPST20 (data) {
    return this.request('post', '/survey/surveySPST20', data);
  }

  updateSurveySPST20 (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/survey/surveySPST20/id/' + id, data);
  }

  getSurveySPST20List (data) {
    return this.request('get', '/survey/surveySPST20', data);
  }

  getMySurveySPST20 (data) {
    return this.request('get', '/survey/mySurveySPST20', data);
  }

  getSurveySPST20 (data) {
    const id = data._id;
    return this.request('get', '/survey/surveySPST20/id/' + id, data);
  }
  /* end SPST20Survey */

  /* MercurySurvey */
  saveSurveyMercury (data) {
    return this.request('post', '/survey/surveyMercury', data);
  }

  updateSurveyMercury (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/survey/surveyMercury/id/' + id, data);
  }

  getSurveyMercuryList (data) {
    return this.request('get', '/survey/surveyMercury', data);
  }

  getMySurveyMercury (data) {
    return this.request('get', '/survey/mySurveyMercury', data);
  }

  getSurveyMercury (data) {
    const id = data._id;
    return this.request('get', '/survey/surveyMercury/id/' + id, data);
  }

  uploadFileMercury (data) {
    return this.request('upload', '/uploadFile/uploadMercuryFile', data);
  }
  /* end MercurySurvey */

  /* Smoke and sound survey */
  saveSurveySmokeAndSound (data) {
    return this.request('post', '/survey/surveySmokeAndSound', data);
  }

  updateSmokeAndSound (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/survey/surveySmokeAndSound/id/' + id, data);
  }

  getSmokeAndSoundList (data) {
    return this.request('get', '/survey/surveySmokeAndSound', data);
  }

  getMySmokeAndSound (data) {
    return this.request('get', '/survey/mySurveySmokeAndSound', data);
  }

  getSmokeAndSound (data) {
    const id = data._id;
    return this.request('get', '/survey/surveySmokeAndSound/id/' + id, data);
  }

  uploadFileSmokeandsound (data) {
    return this.request('upload', '/uploadFile/uploadSmokeAndSoundFile', data);
  }
  /* End Smoke and sound survey */

  saveSurveyLichen2 (data) {
    return this.request('post', '/survey/surveyLichen', data);
  }

  getServeyLichen2 (data) {
    return this.request('get', '/survey/surveyLichen/id/' + data._id, data);
  }

  updateLichen2 (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/survey/surveyLichen/id/' + id, data);
  }

  getLichenans2 (data) {
    return this.request('get', '/survey/mysurveylichen', data);
  }
}

export default SurveyProvider;
