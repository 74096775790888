import { createApp } from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import i18n from './i18n';
import store from './store';
import router from './router';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { vfmPlugin } from 'vue-final-modal';

loadFonts();

const optionsSweetalert2 = {
  confirmButtonColor: '#23a38a'
  // cancelButtonColor: '#a19a9a',
};

// createApp(App)
//   .use(router)
//   .use(store)
//   .use(i18n)
//   .use(vuetify)
//   .use(VueSweetalert2, optionsSweetalert2)
//   .use(vfmPlugin)
//   .mount('#app');

const app = createApp(App);
app.config.globalProperties.globalVar = {
  NCIDOrganizationID: '6327ff7804a524f8e6180c6f',
  screenID: {
    surveyLichen: '6319b686240210cfdda5583d', // แบบสำรวจไลเคน
    surveySampleNO2: '63298db3ea984d63043141f6', // แบบเก็บตัวอย่าง NO2
    surveySampleRain: '63298dffea984d63043141f9', // แบบเก็บตัวอย่าง น้ำฝน
    surveySampleWater: '63298e44ea984d63043141fc', // แบบเก็บตัวอย่าง น้ำ
    surveyCorpDamage: '6319b715240210cfdda5583f', // แบบสำรวจปัญหาพืชผลเสียหาย
    surveySample: '6319b772240210cfdda55842', // แบบฟอร์มการส่งตัวอย่าง (ปัญหาพืชผลเสียหาย)
    surveyCorpDamageResearcher: '6319b7e3240210cfdda55844', // แบบสำรวจปัญหาพืชผลเสียหาย (นักวิจัย)
    surveyAgriculturalProduct: '6319b857240210cfdda55846', // การเฝ้าระวังคุณภาพอากาศ : ตัวบ่งชี้กรณีพืช ผัก ผลผลิต
    surveyPhysicalHealth: '631ab8e4a5b481668ad6680d', // การเฝ้าระวังผลกระทบทางสุขภาพ : ตัวชี้วัด สุขภาพกาย
    surveyBioBlitz: '631ab958a5b481668ad66810', // การเฝ้าระวังคุณภาพน้ำด้วยกระบวนการชีวะตะลุมบอน (BioBlitz)
    surveyOrangeOrchard: '63298e96ea984d63043141ff', // การเฝ้าระวังคุณภาพอากาศ : ตัวชี้วัด ผลผลิตสวนส้ม ของกลุ่มกษตรกรสวนส้ม อ.ทุ่งช้าง
    surveySPST20: '632c19a6ea984d6304314309', // แบบวัดความเครียด
    surveyMercury: '632d5bb1ea984d6304314323', // แผนที่ความเสี่ยงปรอทตกสะสม อำเภอเฉลิมพระเกียรติ จังหวัดน่าน
    surveySmokeAndSound: '6368925aaa9a72b3291f31fb' // การเฝ้าระวังคุณภาพอากาศ : ควันและเสียงจากแหล่งกำเนิด
  }
};
app.use(router)
  .use(store)
  .use(i18n)
  .use(vuetify)
  .use(VueSweetalert2, optionsSweetalert2)
  .use(vfmPlugin)
  .mount('#app');
